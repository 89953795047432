/* Step 1: Common Properties: All required to make icons render reliably */
@mixin icon {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

/* Step 2: Reference Individual Icons */
/* external link icon, but not for buttons like GitHub or LinkedIn */
:not(.button).target-blank {
  &__icon::after {
    @include icon;

    font-size: smaller;
    vertical-align: super;
    margin-left: .15em;
    content: " \f35d";
  }
}
