$highlight-background   :#002451;
$highlight-foreground   :#fff;
$highlight-comment      :#7285b7;
$highlight-red          :#ff9da4;
$highlight-orange       :#ffc58f;
$highlight-yellow       :#ffeead;
$highlight-green        :#d1f1a9;
$highlight-aqua         :#9ff;
$highlight-blue         :#bbdaff;
$highlight-purple       :#ebbbff;
