@import '_target-blank';

// Use a bigger font - default is 16px
$font-size-root: 21px;

// use Lato as default font - others are 1:1 from the theme
$font-family:      (Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif);
// original definition: $font: map-get($base, font-weight) #{map-get($base, font-size)}/#{map-get($base, line-height)} map-get($base, font-family);
// $font: map-get($base, font-weight) #{map-get($base, font-size)}/#{map-get($base, line-height)} $font-family;

// use Anonymous Pro as default font - others are 1:1 from the theme
$font-family-code: ('Anonymous Pro', Menlo, Monaco, Consolas, Andale Mono, lucida console, Courier New, monospace);


html {
 // font-size: map-get($base, font-size-root);
 font-size: $font-size-root;
}

body {
  // font: $font;
  font-family: $font-family;
}

input, textarea, select, button {
  // font: $font;
  font-family: $font-family;
}

pre, code {
  // font-family: map-get($base, font-family-code);
  font-family: $font-family-code;
}
