$highlight-background   :#000;
$highlight-foreground   :#eaeaea;
$highlight-comment      :#969896;
$highlight-red          :#d54e53;
$highlight-orange       :#e78c45;
$highlight-yellow       :#e7c547;
$highlight-green        :#b9ca4a;
$highlight-aqua         :#70c0b1;
$highlight-blue         :#7aa6da;
$highlight-purple       :#c397d8;
